@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap");

@layer components {
  .btn {
    @apply px-4 py-2 rounded-lg font-medium inline-flex items-center justify-center tracking-tight;
    &:hover {
      @apply shadow-[0_10px_20px_rgba(0,0,0,0.15)];
    }
    &:disabled {
      background-color: #ccc;
      cursor: not-allowed;
    }
  }
  .btn-primary {
    @apply bg-black text-white;
  }
  .btn-secondary {
    @apply border border-[#333] shadow-[0_7px_14px_#EAEAEA] text-black;
    &:hover {
      @apply border-[#555] text-gray-700;
    }
  }
  .btn-text {
    @apply text-black bg-transparent;
  }
  .logo-ticker-image {
    @apply h-16 w-auto;
  }
  /* .tag {
    @apply text-sm inline-flex border border-[#222]/10 px-3 py-1 rounded-lg tracking-tight;
  } */
  .tag {
    @apply text-sm inline-flex px-3 py-1 rounded-lg tracking-tight relative;

    background: linear-gradient(#fff, #fff) padding-box,
      linear-gradient(to right, #f878ff, #fb92cf, #ffdd98, #c2f0b1, #2fd8fe)
        border-box;
    border: 2px solid transparent;
  }
  .section-title {
    @apply text-center text-3xl md:text-[54px] md:leading-[60px] font-bold tracking-tighter bg-gradient-to-b from-black to-[#001E80] text-transparent bg-clip-text;
  }
  .section-description {
    @apply text-center text-[22px] leading-[30px] tracking-tight text-[#010D3E];
  }
  .section-heading {
    @apply max-w-[540px] mx-auto;
  }
  .card {
    @apply p-10 border border-[#f1f1f1] rounded-3xl shadow-[0_7px_14px_#EAEAEA] max-w-xs w-full;
  }
  .hover-link {
    @apply hover:text-white transition duration-300 ease-in-out;
  }
  .hover-icon {
    @apply hover:text-white transition-transform transform hover:scale-110 duration-300 ease-in-out cursor-pointer;
  }
  .link {
    @apply hover:text-gray-600 transition duration-300 ease-in-out;
    &:hover {
      @apply text-gray-950;
    }
  }
  .active-link {
    @apply text-black font-medium;
  }
  .error {
    @apply text-red-600 block text-sm mt-1;
  }
}
@layer base {
  ::selection {
    background: #4a90e2; /* Background color for selected text */
    color: #ffffff; /* Text color for selected text */
  }

  /* Customize selection color for Firefox */
  ::-moz-selection {
    background: #4a90e2; /* Background color for selected text */
    color: #ffffff; /* Text color for selected text */
  }

  /* Customize selection color for Internet Explorer */
  ::-ms-selection {
    background: #4a90e2; /* Background color for selected text */
    color: #ffffff; /* Text color for selected text */
  }
}

body {
  font-family: "DM Sans", sans-serif;
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  width: 8px;
}
::-webkit-scrollbar-track {
  background: none;
}
::-webkit-scrollbar-thumb {
  background-color: #c3cffa;
  border-radius: 10px;
  border: 3px solid transparent;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #d2dcff;
}
/* For Chrome, Safari, Edge, and Opera */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* For Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
/* Tailwind CSS Custom Class */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
